.banner {
  padding: $space-l * 2 $space;
  text-align: center;
  display: flex;
  justify-content: center;

  &__inner {
    max-width: 720px;
    margin: auto;
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    &.button {
      margin-top: $space * 2;
      width: 100%;
      font-size: 22px;

      @include medium-up {
        width: auto;
      }
    }
  }
}
