// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
@use "sass:math";

/******* Colors *******/

  /* Palette */

    // Tip! Name your colors over at http://chir.ag/projects/name-that-color/#36a9e1
    $powder-ash: #B9C5C1;
    $satin-linen: #E2DCCF;
    $cod-gray: #191919;
    $pampas: #F5F3EF;

    $white: #ffffff;
    $black: #000000;

  /* Styling variables (use these in the styling) */
    $color-accent: $cod-gray;

    $color-white: $white;
    $color-black: $black;
    $color-beige: $satin-linen;
    $color-green: $powder-ash;
    $color-off-white: $pampas;
    $color-black-light: $cod-gray;

    $social-media-colors: (
      facebook: #3b5998,
      instagram: #e1306c,
      twitter: #1da1f2,
      linkedin: #0077b5,
      pinterest: #bd081c,
      youtube: #ff0000,
      email: $color-accent,
    );


/******* Fonts *******/

  /* List */

    $bebas-neue: 'Bebas Neue';
    $inter: 'Inter', sans-serif;
    $source-sans-pro: 'Source Sans Pro', sans-serif;
    $futura: 'futura-pt', sans-serif;
    $oswald: 'Oswald', sans-serif;

  /* Styling variables (use these in the styling) */
    $font-body: $inter;
    $font-heading: $bebas-neue;


/******* Sizes *******/

  $max-width: 1180px !default; // Also in includes/Consts.php
  $max-width-text: 750px !default;

  $header-height: 77px;
  $header-height-mobile: 65px;

  $breakpoints: (
    'extra-small': 320px,
    'small': 500px,  // Also in includes/Consts.php
    'small-up': 501px,
    'medium': 760px,
    'medium-up': 761px,
    'tablet-up': 1024px,
    'large': 1080px,
    'large-up': 1081px,
    'extra-large': 1400px,
  ) !default;

  $space: 16px;

  $space-xs: math.div($space, 4);
  $space-s: math.div($space, 2);
  $space-sxs: $space-s + $space-xs;
  $space-m: $space;
  $space-ms: $space + $space-s;
  $space-l: $space * 2;
  $space-lm: $space-l + $space-m;
  $space-xl: $space * 4;
  $space-xll: $space-xl + $space-l;
  $space-xxl: $space * 8;

  $margin-class-map: (
    // Name: (margin-bottom, medium margin-bottom)
    none: (0px, 0px),
    xs: ($space-xs, $space-xs),
    s: ($space-s, $space-s),
    m: ($space-m, $space-m),
    l: ($space-l, $space-m),
    lm: ($space-lm, $space-l),
    xl: ($space-xl, $space-l),
  );

  $default-mc-block: 'l';
  $default-mc-block-typography: 'm';
  $default-mc-block-heading: 's';
  $default-mc-comp-hero: 'lm';

  $typography-elements: (
    'standard': (
      ol,
      ul,
      p,
    ),
    'heading': (
      h1,
      h2,
      h3,
    ),
  );


/******* Other *******/

  $border-radius-common: 0px;

  $transition: .2s;

  $z-index-layers: (
    hidden: -2,
    background: -1,
    base: 1,
    content: 10,
    chrome: 500,
    fullscreen: 1000,
    modal: 1500,
  );

  $base-url-absolute: '/wp-content/themes/notskrikan-theme/assets/' !default;
  $base-url-relative: '../../' !default;
  $base-url: $base-url-relative !default;

  $is-admin: false !default;

/******* Variables from build system during compilation *******/
  $env: 'production' !default;
