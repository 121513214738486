// Some rules relating to typography also in _base, to reduce multiple rules for the same selector.

strong {
  font-weight: 400;
}

h1 {
  font-family: $oswald;
  font-weight: 200;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 2.3rem;

  @include small {
    font-size: 34px;
    line-height: 3.2rem;
    padding: $space-m 0;
  }
}

h2 {
  font-family: $oswald;
  font-weight: 200;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 2.3rem;

  @include small {
    font-size: 24px;
    line-height: 3.2rem;
    padding: $space-m 0;
  }
}

h3 {
  font-family: $oswald;
  font-weight: 200;
  font-size: 19px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  @include small {
    font-size: 16px;
  }
}

h4 {
  font-family: $oswald;
  font-weight: 200;
  font-size: 19px;
  text-transform: uppercase;

  @include small {
    font-size: 16px;
  }
}

h5 {
  font-size: 34px;
  font-weight: 400;
  font-family: $inter;
  text-transform: uppercase;

  @include small {
    font-size: 16px;
  }
}

h6 {
  font-size: 18px;
  font-weight: 400;
  font-family: $inter;

  @include small {
    font-size: 16px;
  }
}

p {
  font-family: $inter;
  font-weight: 300;
  font-size: 16px;

  @include small {
    font-size: 16px;
  }
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    color: darken($color-accent, 10);
  }
}

// p a:not([class]) {
//   @extend %inline-anchor;
// }

// @include medium-up {
//   .site-content {
//     p,
//     ol,
//     ul {
//       font-size: 120%;
//       font-weight: 300;
//     }
//   }
// }

.has-text-align-center {
  text-align: center;
}
