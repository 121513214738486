/* Bebas Neue Regular */
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: font(
    "bebas-neue/bebas-neue-v9-latin-regular.eot"
  ); /* IE9 Compat Modes */
  src: local(""),
    font("bebas-neue/bebas-neue-v9-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ font("bebas-neue/bebas-neue-v9-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      font("bebas-neue/bebas-neue-v9-latin-regular.woff") format("woff"),
    /* Modern Browsers */ font("bebas-neue/bebas-neue-v9-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      font("bebas-neue/bebas-neue-v9-latin-regular.svg#BebasNeue") format("svg"); /* Legacy iOS */
}

/* Bebas Neue Book */
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 300;
  src: font("bebas-neue/bebas-neue-book.ttf"); /* IE9 Compat Modes */
  src: local(""), font("bebas-neue/bebas-neue-book.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Inter light */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: font("inter/inter-v11-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("inter/inter-v11-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("inter/inter-v11-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ font("inter/inter-v11-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ font("inter/inter-v11-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("inter/inter-v11-latin-300.svg#Inter")
      format("svg"); /* Legacy iOS */
}
/* Inter regular */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: font("inter/inter-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("inter/inter-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("inter/inter-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ font("inter/inter-v11-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ font("inter/inter-v11-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("inter/inter-v11-latin-regular.svg#Inter")
      format("svg"); /* Legacy iOS */
}

/* Inter medium */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: font("inter/inter-v11-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("inter/inter-v11-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("inter/inter-v11-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ font("inter/inter-v11-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ font("inter/inter-v11-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("inter/inter-v11-latin-500.svg#Inter")
      format("svg"); /* Legacy iOS */
}

/* oswald-200 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 200;
  src: font("oswald/oswald-v48-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("oswald/oswald-v48-latin-200.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("oswald/oswald-v48-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ font("oswald/oswald-v48-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ font("oswald/oswald-v48-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("oswald/oswald-v48-latin-200.svg#Oswald")
      format("svg"); /* Legacy iOS */
}
/* oswald-300 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  src: font("oswald/oswald-v48-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("oswald/oswald-v48-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("oswald/oswald-v48-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ font("oswald/oswald-v48-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ font("oswald/oswald-v48-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("oswald/oswald-v48-latin-300.svg#Oswald")
      format("svg"); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: font("oswald/oswald-v48-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("oswald/oswald-v48-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ font("oswald/oswald-v48-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ font("oswald/oswald-v48-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ font("oswald/oswald-v48-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      font("oswald/oswald-v48-latin-regular.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: font("oswald/oswald-v48-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("oswald/oswald-v48-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("oswald/oswald-v48-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ font("oswald/oswald-v48-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ font("oswald/oswald-v48-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("oswald/oswald-v48-latin-500.svg#Oswald")
      format("svg"); /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: font("oswald/oswald-v48-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    font("oswald/oswald-v48-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ font("oswald/oswald-v48-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ font("oswald/oswald-v48-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ font("oswald/oswald-v48-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ font("oswald/oswald-v48-latin-600.svg#Oswald")
      format("svg"); /* Legacy iOS */
}
