.page-head {
  $self: ".page-head";

  @include medium-up {
    display: flex;
    justify-content: space-between;
  }

  &--fill-viewport {
    display: block;
    height: calc(100vh - 65px);
    position: relative;

    @include small-up {
      height: calc(100vh - 116px);
    }

    #{$self}__inner {
      max-width: 800px;
      padding: $space;
      position: absolute;
      top: 45%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      width: 100%;

      @include medium-up {
        top: 40%;
      }

      p {
        max-width: 650px;
        margin: auto;
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 2;

    @include medium-up {
      width: 45%;
    }

    h1 {
      margin-bottom: 0.5em;
    }

    p,
    a {
      font-size: 110%;

      @include large-up {
        font-size: 125%;
      }
    }

    a {
      border-bottom: 4px solid $color-accent;
      color: $color-black;
      display: inline-block;
      font-weight: 600;
      margin-top: $space;
    }
  }

  &__extra {
    background: $color-white;
    border-top: $space-xs solid $color-accent;
    margin-top: $space;
    padding: $space;

    @include medium-up {
      width: 45%;
    }

    @include large-up {
      padding: $space-l;
    }

    p {
      margin-bottom: 1rem;

      &:last-of-type {
        margin: 0;
      }
    }

    &.contact {
      strong {
        font-size: 150%;
        font-weight: 400;
      }

      a {
        color: $color-black;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

// Work with us
.page-head {
  &.work-with-us {
    background-image: url(#{$base-url}img/work-with-us/gladje-o50.png);
    background-position: -10% 5%;
    background-repeat: no-repeat;
    background-size: 45%;

    @include medium-up {
      background-size: 35%;
      background-position: -15% 5%;
    }

    @include tablet-up {
      background-image: url(#{$base-url}img/work-with-us/gladje.png);
      background-position: -5% 5%;
      background-size: 25%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: url(#{$base-url}img/work-with-us/gemenskap-o50.png);
      background-position: -25% 85%;
      background-repeat: no-repeat;
      background-size: 50%;

      @include medium-up {
        background-position: -2% 85%;
        background-size: 35%;
      }

      @include tablet-up {
        background-image: url(#{$base-url}img/work-with-us/gemenskap.png);
        background-position: 20% 55vh;
        background-size: 30%;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-image: url(#{$base-url}img/work-with-us/trygghet-o50.png);
      background-position: 115% 50%;
      background-repeat: no-repeat;
      background-size: 40%;

      @include medium-up {
        background-size: 35%;
      }

      @include tablet-up {
        background-image: url(#{$base-url}img/work-with-us/trygghet.png);
        background-position: 110% 50%;
        background-size: 28%;
      }
    }
  }
}

.green-box {
  max-width: 900px !important;
  padding: 75px;
  background-color: $color-green;

  p {
    white-space: nowrap;
  }
}
