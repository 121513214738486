*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font: normal normal normal 1rem/1.6 $font-body;
  background: $color-off-white;
  color: $color-black-light;
}

@include admin-page(true) {
  body & {
    font: normal normal normal 1rem/1.6 $font-body;
    background: $color-white;
  }
}

html,
body {
  height: 100%;
}

body.no-scroll {
  overflow: hidden;
}

input,
textarea {
  font-size: 1rem;
  font-family: inherit;
}

.site-content {
  margin-top: 85px;

  body.home & {
    margin-top: auto;
  }

  @include small-up {
    margin-top: auto;
  }
}
