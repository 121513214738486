// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
@mixin small {
  @media (max-width: map-get($breakpoints, small)) {
    @content;
  }
}

@mixin small-up {
  @media (min-width: map-get($breakpoints, small-up)) {
    @content;
  }
}

@mixin medium {
  @media (max-width: map-get($breakpoints, medium)) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: map-get($breakpoints, medium-up)) {
    @content;
  }
}

@mixin large {
  @media (max-width: map-get($breakpoints, large)) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: map-get($breakpoints, large-up)) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: map-get($breakpoints, tablet-up)) {
    @content;
  }
}

@mixin when-inside($context, $selector: &) {
  @if $is-admin {
    @at-root {
      .editor-styles-wrapper #{$context} #{remove-first($selector)} {
        @content;
      }
    }
  } @else {
    #{$context} & {
      @content;
    }
  }
}

@mixin center($x: true, $y: true) {
  position: absolute;
  @if ($x and $y) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($x) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($y) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
  @supports (-ms-accelerator: true) {
    @content;
  }
}

@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin full-vw {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

@mixin soft-linear-gradient($color: #000, $startpoint: 1, $deg: 180deg) {
  background-image: linear-gradient($deg,
    rgba($color, $startpoint) 0%,
    rgba($color, $startpoint * 0.738) 19%,
    rgba($color, $startpoint * 0.541) 34%,
    rgba($color, $startpoint * 0.382) 47%,
    rgba($color, $startpoint * 0.278) 56.5%,
    rgba($color, $startpoint * 0.194) 65%,
    rgba($color, $startpoint * 0.126) 73%,
    rgba($color, $startpoint * 0.075) 80.2%,
    rgba($color, $startpoint * 0.042) 86.1%,
    rgba($color, $startpoint * 0.021) 91%,
    rgba($color, $startpoint * 0.008) 95.2%,
    rgba($color, $startpoint * 0.002) 98.2%,
    rgba($color, 0) 100%
  );
}

@mixin admin-page($condition: true) {
  @if $is-admin == $condition {
    @content;
  }
}
