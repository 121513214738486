// IMAGE GALLERY WITH BANNER

.image-gallery-banner {
  display: flex;
  width: 100%;
  flex-direction: column;

  .box-7 {
    padding-bottom: $space-lm;
  }

  .box-1 {
    background: $color-beige;
    text-align: center;
    padding: $space-xl $space-m;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    p {
      padding-bottom: $space-l;
    }

    .button {
      display: inline-block;
      margin: auto;
      width: 100%;
      margin-bottom: 0.7rem;

      @include medium-up {
        width: 340px;
      }
    }
  }
  img {
    padding-top: $space-m;
  }
  .ofi-holder {
    height: 100%;
  }
}

@include medium-up {
  .image-gallery-banner {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(18, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 12;
      grid-row: 1 / span 4;
      padding: $space-xl $space-lm;
      p {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .box-2 {
      grid-column: 1 / span 6;
      grid-row: 5 / span 3;
    }

    .box-3 {
      grid-column: 7 / span 6;
      grid-row: 5 / span 4;
    }
    .box-6 {
      grid-column: 1 / span 6;
      grid-row: 8 / span 7;
    }
    .box-5 {
      grid-column: 7 / span 6;
      grid-row: 9 / span 7;
    }
    .box-4 {
      grid-column: 1 / span 6;
      grid-row: 15 / span 4;
    }
    .box-7 {
      grid-column: 7 / span 6;
      grid-row: 16 / span 3;
      padding-bottom: 0;
    }
  }
}

@include large-up {
  .image-gallery-banner {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(12, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      padding: $space-xl $space-lm;
      grid-column: 1 / span 8;
      grid-row: 1 / span 4;
    }

    .box-2 {
      grid-column: 9 / span 4;
      grid-row: 1 / span 3;
    }

    .box-3 {
      grid-column: 1 / span 4;
      grid-row: 5 / span 3;
    }

    .box-4 {
      grid-column: 5 / span 4;
      grid-row: 5 / span 4;
    }

    .box-5 {
      grid-column: 9 / span 4;
      grid-row: 4 / span 5;
    }

    .box-6 {
      grid-column: 1 / span 4;
      grid-row: 8 / span 5;
    }

    .box-7 {
      grid-column: 5 / span 8;
      grid-row: 9 / span 4;
      padding-bottom: 0;
    }
  }
}

// IMAGE GALLERY 3 IMAGES
.image-gallery-3 {
  display: flex;
  width: 100%;
  flex-direction: column;

  .ofi-holder {
    height: 100%;
  }

  img {
    padding-top: $space-m;
  }
}

@include medium-up {
  .image-gallery-3 {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(3, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 12;
      grid-row: 1 / span 2;
    }

    .box-2 {
      grid-column: 1 / span 6;
      grid-row: 3 / span 1;
    }

    .box-3 {
      grid-column: 7 / span 6;
      grid-row: 3 / span 1;
    }
  }
}

@include large-up {
  .image-gallery-3 {
    display: grid;
    width: 100%;
    height: 550px;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(8, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 8;
      grid-row: 1 / span 8;
    }

    .box-2 {
      grid-column: 9 / span 4;
      grid-row: 1 / span 4;
    }

    .box-3 {
      grid-column: 9 / span 4;
      grid-row: 5 / span 4;
    }
  }
}

// IMAGE GALLERY WITH COLUMN
.image-gallery-column {
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    padding-top: $space-m;
  }

  .ofi-holder {
    height: 100%;
  }

  .box-3 {
    margin-top: $space-m;
    background: $color-beige;
    text-align: center;
    padding: $space-l;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      padding-top: $space-m;
      font-size: 100px;
      line-height: 1rem;
    }

    p {
      font-family: $inter;
      font-size: 34px;
      padding-bottom: $space-m;
    }
  }
}

@include medium-up {
  .image-gallery-column {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(18, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-3 {
      margin-top: 0;
      grid-column: 1 / span 6;
      grid-row: 1 / span 12;
    }

    .box-2 {
      grid-column: 7 / span 6;
      grid-row: 1 / span 6;
    }

    .box-1 {
      grid-column: 1 / span 6;
      grid-row: 13 / span 6;
    }

    .box-4 {
      grid-column: 7 / span 6;
      grid-row: 7 / span 12;
    }
  }
}

@include large-up {
  .image-gallery-column {
    display: grid;
    width: 100%;
    height: 550px;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(12, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 4;
      grid-row: 1 / span 6;
    }

    .box-2 {
      grid-column: 1 / span 4;
      grid-row: 7 / span 6;
    }

    .box-3 {
      margin-top: 0;
      grid-column: 5 / span 4;
      grid-row: 1 / span 12;
    }

    .box-4 {
      grid-column: 9 / span 4;
      grid-row: 1 / span 12;
    }
  }
}

// IMAGE GALLERY QUOTE

.image-gallery-quote {
  display: flex;
  width: 100%;
  flex-direction: column;

  img {
    padding-top: $space-m;
  }
  .ofi-holder {
    height: 100%;
  }

  .box-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background: $color-beige;
    text-align: center;
    padding: 2.2rem;
    padding-top: 4rem;

    .box-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-size: 8rem;
    }
    p {
      font-size: 18px;
    }

    h3 {
      padding-top: $space-l;
      font-size: 18px;
      letter-spacing: 0;
    }
  }
}

@include medium-up {
  .image-gallery-quote {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(12, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 12;
      grid-row: 1 / span 4;
    }

    .box-3 {
      grid-column: 1 / span 6;
      grid-row: 5 / span 4;
    }

    .box-2 {
      grid-column: 7 / span 6;
      grid-row: 5 / span 3;
    }
    .box-4 {
      grid-column: 1 / span 6;
      grid-row: 9 / span 4;
    }

    .box-5 {
      grid-column: 7 / span 6;
      grid-row: 8 / span 5;
    }
  }
}

@include large-up {
  .image-gallery-quote {
    display: grid;
    width: 100%;
    height: auto;
    grid-gap: $space-m;

    grid-template-columns: repeat(12, 1fr [col-start]);
    grid-template-rows: repeat(6, 1fr [row-start]);

    img {
      padding-top: 0;
    }

    .box-1 {
      grid-column: 1 / span 8;
      grid-row: 1 / span 3;
    }

    .box-2 {
      grid-column: 9 / span 4;
      grid-row: 1 / span 2;
    }

    .box-3 {
      grid-column: 1 / span 4;
      grid-row: 4 / span 3;
    }

    .box-4 {
      grid-column: 5 / span 4;
      grid-row: 4 / span 3;
    }

    .box-5 {
      grid-column: 9 / span 4;
      grid-row: 3 / span 4;
    }
  }
}
