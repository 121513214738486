.styled-list {
  $self: &;

  &__list {
    list-style: none;
  }

  &__bullet {
    border: 1px solid $color-black-light;
    display: flex;
    margin-bottom: $space;
    padding: $space-sxs;
    align-items: flex-start;
    margin-bottom: 0 !important;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include large-up {
      // padding: $space;
    }
  }

  &--no-bg {
    #{$self}__bullet {
      background: none;
    }
  }

  &--border {
    #{$self}__bullet {
      border: 1px solid #000000;
    }
  }
}

.table-container {
  .container__inner {
    max-width: 750px;
    margin: auto;

    .wp-container-6 {
      gap: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .wp-block-column {
      margin-right: 0;
      margin-bottom: 0;
    }
    h2 {
      border: 1px solid black;
      margin-bottom: 0 !important;
      padding: $space-m 0;
      border-bottom: 0;
    }

    .h2-less-padding {
      padding: $space-s 0;
      border-bottom: 0;
    }

    li {
      border-bottom: 0;
    }

    span {
      font-family: $oswald;
      font-weight: 200;
      font-size: 18px;
      text-transform: uppercase;
    }

    .border-bottom:last-child {
      border-bottom: 1px solid black;
    }

    @include medium-up {
      .border-right {
        li {
          border-right: 0 !important;
        }
      }
    }

    @include medium {
      .border-right:last-child {
        border-bottom: 0 !important;
      }
    }
  }
}
