// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.icon {
  svg {
    display: block;
    fill: currentColor;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    overflow: hidden;
  }

  @include admin-page(true) {
    &:not(.icon--preview) {
      svg {
        background: $color-black-light;
      }
    }
  }
}

.access-label {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.lazy-image-preview {
  filter: blur(0px);
  transform: scale(1);
  transition: filter $transition, transform $transition;

  &--lazy-not-loaded {
    filter: blur(3px);
    transform: scale(1.03);
  }
}
