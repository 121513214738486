.hero {
  &__container {
    padding-bottom: 30%; // 10:3. If you change this, then also change the aspect ratio for image in Hero.php
    overflow: hidden;
    position: relative;

    @include small {
      padding-bottom: 75%; // 4:3. If you change this, then also change the aspect ratio for image in Hero.php
    }

    @include when-inside(#{block()}--no-image) {
      background: $color-black-light;
    }

    @include when-inside(#{block()}--overlay) {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.2);
      }
    }
  }

  &__content-holder {
    @include center();
    width: 100%;
    padding: $space;
    text-align: center;
    z-index: z-index(content);
  }

  &__content {
    max-width: 650px;
    margin: 0 auto;
    color: $color-white;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &--fullscreen {
    @include admin-page(false) {
      height: 80vh;
      width: 100%;

      #{block()}__container {
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
