.site-header {
  padding-top: $space-m;

  .notskrikan-logo {
    // margin-top: $space-l;
    max-width: 70px;
    background: $color-off-white;
    padding: $space-s;
    border-radius: 4rem;

    @include medium-up {
      max-width: 110px;
      padding: $space-m;
      margin-top: 0;
    }

    // @include medium {
    //  opacity: 0;
    // }
  }

  body.home & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .menu-item {
    list-style: none;
    display: inline;
  }

  &__bar {
    position: relative;
    z-index: z-index(chrome);
  }

  &--floating {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .primary-menu {
      a {
        color: $color-accent !important;
        font-family: $oswald;
        font-size: 19px;
        font-weight: 200;
        text-transform: uppercase;
      }
      .hamburger {
        &__slice {
          background: $color-white;
        }
      }
    }
  }
}

// .logo {
//   display: block;
//   flex: 1 1 auto;

//   img {
//     display: block;
//     width: 100%;

//     &:nth-of-type(2) {
//       display: none;
//     }
//   }

// &--negative {
//   img {
//     &:nth-of-type(1) {
//       display: none;
//     }
//     &:nth-of-type(2) {
//       display: block;
//     }
//   }
// }
//}

.primary-menu {
  @include small {
    // background: $color-off-white;
    padding-bottom: 0.6rem;
    // temp
    // display: none;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .logo {
  //   max-width: 180px;
  // }

  &__nav {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    flex: 0 0 auto;
    background: $color-off-white;
    border-radius: 25px;
    padding: $space-s $space-l;

    @include medium {
      display: none; // temp
    }
  }

  .hamburger {
    margin-left: $space-l;
    &__slice {
      background: $color-black;

      body.home & {
        background: $color-white;
      }
    }
  }

  .menu {
    margin-left: -$space-m;
    margin-right: -$space-m;
  }

  .menu-item {
    margin: 0 $space-m;

    a {
      color: $color-black;
    }

    &.current-menu-item a,
    a:hover {
      // border-bottom: 3px solid $color-accent;
      color: $color-black;
    }

    &.accent {
      a {
        background: $color-accent;
        border-radius: $space;
        color: $color-white;
        display: inline-block;
        padding: $space-xs $space-m;

        &:hover {
          border-bottom: 0;
        }
      }
    }

    body.home & {
      a {
        color: $color-white;
      }
    }
  }
}

.hamburger {
  display: block; // temp
  background: transparent;

  width: 30px;
  height: 24px;
  border: none;

  cursor: pointer;
  position: relative;
  transition: transform $transition * 1.5;
  // background: $color-off-white;

  &__slice {
    display: block;
    width: 100%;
    height: 3px;
    background: $color-black;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%) rotate(0deg) scale(1);
    transition: transform $transition * 1.2, opacity $transition * 1.2;
    transition-timing-function: ease-in-out;
    border-radius: 1rem;

    &:first-child {
      top: 0;
    }

    &:last-child {
      top: 100%;
    }
  }
  @include medium-up {
    display: none;
  }
}

.mobile-menu-header {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 $space;
  height: $header-height-mobile;
  // background: $color-off-white;
  z-index: z-index(chrome);

  body.home & {
    position: absolute;
    background: transparent;
  }

  @include small {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.6rem;
    background: transparent;
  }

  .hamburger {
    flex: 0 0 auto;
  }
}

#side-menu {
  display: none;
}
