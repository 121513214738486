@mixin layout-width-styling {
  margin-right: auto;
  margin-left: auto;

  &--mw-cntnt {
    max-width: $max-width;
  }

  &--mw-cntnt-pd {
    max-width: $max-width;

    @media screen and (max-width: #{$max-width + $space-ms * 2}) {
      padding-left: calc(#{$space-ms} - ((100vw - #{$max-width}) / 2));
      padding-right: calc(#{$space-ms} - ((100vw - #{$max-width}) / 2));
    }

    @media screen and (max-width: #{$max-width}) {
      padding-left: $space-ms;
      padding-right: $space-ms;
    }

    @include small {
      padding-left: $space-m;
      padding-right: $space-m;
    };
  }

  &--fw-pd {
    padding-left: $space-ms;
    padding-right: $space-ms;

    @media screen and (max-width: #{$max-width}) {
      padding-left: $space-ms;
      padding-right: $space-ms;
    }

    @include small {
      padding-left: $space-m;
      padding-right: $space-m;
    };
  }

  &--mw-txt {
    max-width: $max-width-text;
    padding: 0 $space-m;
  }
}

@include admin-page(false) {
  .bw--lw > .lw {
    @include layout-width-styling;
  }
};

@include admin-page(true) {
  .is-root-container > .lw,
  .bw--lw .block-editor-block-list__layout > .lw,
  .acf-block-preview > .lw,
  .wp-block > .lw,
  {
    @include layout-width-styling;
  }
};

.bw {
  /*
   * Set default layout width to core typography elements on frontend.
  /* ********** */
  @each $element in map-get($typography-elements, 'standard') {
    & > #{$element} {
      &:not(.lw) {
        @extend .lw, .lw--mw-txt !optional;
      }
    }
  }

  @each $element in map-get($typography-elements, 'heading') {
    & > #{$element} {
      &:not(.lw) {
        @extend .lw, .lw--mw-txt !optional;
      }
    }
  }
  /* ********** */
}
